<template>
  <div class="wrapper workorders-view-overview">
    <TitleBar :workOrder="workOrder"/>

    <!-- Basic timeline and status info -->
    <v-row class="basic-info align-center">
      <v-col sm="4" class="datetime datetime-left" style="padding-left: 16px">
        <h2>{{ niceDateFromDateTime(workOrder.datetime_start) }}</h2>
        <h3>{{ relativeDateFromDateTime(workOrder.datetime_start) }}</h3>
      </v-col>
      <v-col sm="4" class="status">
        <h2  :class="`text-${workOrder.status ? determineStatusColor(workOrder.status.name) : 'black'}`">
          {{ workOrder.status ? text.workOrders.statuses[workOrder.status.name] : '' }}
        </h2>
        <p>{{ workOrder.category }}</p>
      </v-col>
      <v-col sm="4" class="datetime datetime-right" style="padding-right: 16px">
        <h2>{{ hourFromDateTime(workOrder.datetime_start) }}</h2>
        <h3>{{  relativeDateFromDateTime(workOrder.datetime_start, true) }}</h3>
      </v-col>
    </v-row>

    <!-- Description, contacts, instructions, location and equipment -->
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col sm="6" class="contacts">
            <h2>{{ workOrder.description }}</h2>
            <v-list two-line>
              <ListItemWithIcon
                v-for="responsible in responsibles"
                :key="responsible.name"
                icon="mdi-phone"
                :title="`Uitvoerder ${workOrder.client ? workOrder.client.name : ''}`"
                :subtitle="responsible.name"
                :href="`tel:${responsible.phone}`"
              />
              <ListItemWithIcon
                icon="mdi-phone"
                title="Contact Van Amerongen"
                :subtitle="workOrder.responsible ? workOrder.responsible.name : ''"
                :href="`tel:${workOrder.responsible ? workOrder.responsible.phone : ''}`"
              />
            </v-list>
          </v-col>
          <v-col sm="6" class="location">
            <a :href="`https://maps.google.com/?q=${getGoogleMapsUrl(this.workOrder.location)}`" target="_blank">
              <div
                class="google-maps-image"
                :style="getGoogleMapsImageStyle(workOrder.location)"
              />
            </a>
            <strong>{{ locationToAddress }}</strong>
          </v-col>
          <v-col sm="12" class="instructions mb-6">
            <h2 class="subtitle">Instructies</h2>
            <p v-if="workOrder.instructions" v-html="nl2br(workOrder.instructions)"></p>
            <p v-else>
              Geen instructies
            </p>
          </v-col>

          <v-col sm="12"
            v-if="
            !workOrder.is_for_equipment_movement ||
            ( workOrder.is_for_equipment_movement &&
            ( workOrder.category === 'Materiaal brengen naar vestiging' || workOrder.category === 'Materiaal terugbrengen naar verhuur leverancier' )
            )
            ">
            <div class="equipment">
              <h2 class="subtitle h-25px mt-0 mb-4">Materiaal geplaatst via deze werkorder</h2>

              <template v-if="hasPlacedEquipment">
                <EquipmentTable :equipment="placedEquipmentGroupedByLocation" :workOrder="workOrder"/>
              </template>

              <template v-else>
                <p class="mb-7">Er is (nog) geen materiaal geplaatst via deze werkorder</p>
              </template>

            </div>
          </v-col>

          <v-col sm="12"
            v-if="
            !workOrder.is_for_equipment_movement ||
            ( workOrder.is_for_equipment_movement &&
            ( workOrder.category === 'Materiaal ophalen bij vestiging' || workOrder.category === 'Materiaal ophalen bij verhuur leverancier' )
            )
            ">
            <div class="equipment">
              <h2 class="subtitle h-25px mt-0 mb-4">Materiaal opgehaald via deze werkorder</h2>

              <template v-if="hasCollectedEquipment">
                <EquipmentTable :equipment="collectedEquipmentGroupedByLocation" :greyOutCollectedEquipment="false" :workOrder="workOrder"/>
              </template>

              <template v-else>
                <p class="mb-7">Er is (nog) geen materiaal opgehaald via deze werkorder</p>
              </template>

            </div>
          </v-col>

          <!-- Files -->
          <v-col sm="12">
            <div class="attachments" style="margin-top: -30px;">
              <h2 class="subtitle">Bijlagen</h2>
              <template v-if="workOrder.files.length === 0">
                <p>Geen bijlagen</p>
              </template>
              <template v-else>
                <v-row
                  v-for="(file, index) in workOrder.files"
                  :key="index"
                  class="file align-center"
                  @click="triggerFilePreview(file)"
                >
                  <v-col sm="2" class="avatar-wrapper">
                    <img
                      v-if="file.image"
                      :src="loadPreview(file)"
                      class="preview"
                    >
                    <div v-else>
                      <v-avatar class="teal">
                        <span class="white--text headline">{{ file.extension }}</span>
                      </v-avatar>
                    </div>
                  </v-col>
                  <v-col sm="6">
                    <h2 class="filename">{{ file.filename }}</h2>
                    <p v-if="file.comments">
                      {{ file.comments }}
                    </p>
                  </v-col>
                  <v-col sm="4" class="text-xs-right">
                    <strong>{{ file.uploaded_by ? file.uploaded_by.name : 'UNKNOWN' }}</strong><br>
                    <span :inner-html.prop="dateOnOm(file.created_at)" /><br>
                    <strong>{{ formatBytes(file.size) }}</strong>
                  </v-col>
                </v-row>
              </template>
            </div>
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>

    <!-- File open Modal -->
    <Dialog
      v-if="filePreviewModal"
      :visible.sync="filePreviewModal"
      @update:visible="filePreviewModal = $event"
      :content-class="filePreview.image ? 'dialog-image' : 'dialog-file'"
      :max-width="`80%`"
      :title="filePreview.filename || 'Loading...'"
    >
      <template v-if="filePreview" v-slot:content>
        <div class="download">
          Download
          <a
            :download="filePreview.filename"
            href="#"
            target="_blank"
            @click.prevent="openDocument(filePreview)"
          >
          {{ filePreview.filename }}
        </a>
        </div>
        <img v-if="filePreview.image && filePreview.data" :src="filePreview.data">
        <iframe v-else :src="filePreview.data" />
      </template>

      <template v-if="filePreview && filePreview.href" v-slot:content>
        <div class="download">
          Download <a :download="filePreviewName" :href="fromStorage(filePreview.href)" target="_blank">{{ filePreviewName }}</a>
        </div>

        <img v-if="filePreview.image" :src="fromStorage(filePreview.href)">
        <iframe v-else :src="fromStorage(filePreview.href)" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog'
import ListItemWithIcon from '@/components/ListItemWithIcon'
import textMixin from '@/mixins/text'
import workOrdersMixin from '@/mixins/workOrders'
import { niceDateFromDateTime, relativeDateFromDateTime, hourFromDateTime, nl2br, dateOnOm, fromStorage, formatBytes } from '@/utils/filters'
import { fetchJobEquipmentLocations } from '@/api/equipment'
import EquipmentTable from '@/components/UI/EquipmentTable'
import TitleBar from '@/components/WorkOrders/TitleBar'
import {loadImageData, streamDownload} from "@/helpers";

export default {
  components: {
    Dialog,
    ListItemWithIcon,
    EquipmentTable,
    TitleBar,
  },

  mixins: [textMixin, workOrdersMixin],

  data() {
    return {
      filePreviewModal: false,
      filePreview: { },
      equipment: [],
      relativeDateFromDateTime: relativeDateFromDateTime,
      hourFromDateTime: hourFromDateTime,
      niceDateFromDateTime: niceDateFromDateTime,
      nl2br: nl2br,
      dateOnOm: dateOnOm,
      fromStorage: fromStorage,
      formatBytes: formatBytes,
      previewImages: {}
    }
  },

  computed: {
    workOrder() {
      return this.$store.getters.workOrdersView
    },
    locationToAddress() {
      return this.getLocationAddress(this.workOrder.location)
    },
    locationFromAddress() {
      return this.workOrder.locationFromText || ''
    },
    filePreviewName() {
      if (!this.filePreview || !this.filePreview.href) {
        return ''
      }

      const parts = this.filePreview.href.split('/')
      return parts[parts.length - 1]
    },
    responsibles() {
      const responsibles = []
      if (this.workOrder.client_responsible_1) {
        responsibles.push(this.workOrder.client_responsible_1)
      }
      if (this.workOrder.client_responsible_2) {
        responsibles.push(this.workOrder.client_responsible_2)
      }

      return responsibles
    },
    placedEquipmentGroupedByLocation() {
      if (!this.equipment || !this.equipment.data) {
        return {};
      }

      const groupedData = {};

      this.equipment.data.forEach(item => {
        const locationId = item.location.id;

        if (!groupedData[locationId]) {
            groupedData[locationId] = [];
        }
        if(item.placed_by_work_order_id === this.workOrder.id) {
          groupedData[locationId].push(item);
        }
      });

      // remove empty locations
      Object.keys(groupedData).forEach(key => {
          if (groupedData[key].length === 0) {
              delete groupedData[key];
          }
      });

      return groupedData;
    },
    hasPlacedEquipment() {
      return Object.keys(this.placedEquipmentGroupedByLocation).length > 0
    },
    collectedEquipmentGroupedByLocation() {
      if (!this.equipment || !this.equipment.data) {
        return {};
      }

      const groupedData = {};

      this.equipment.data.forEach(item => {
        const locationId = item.location.id;

        if (!groupedData[locationId]) {
            groupedData[locationId] = [];
        }
        if(item.collected_by_work_order_id === this.workOrder.id) {
          groupedData[locationId].push(item);
        }
      });

      // remove empty locations
      Object.keys(groupedData).forEach(key => {
          if (groupedData[key].length === 0) {
              delete groupedData[key];
          }
      });

      return groupedData;
    },
    hasCollectedEquipment() {
      return Object.keys(this.collectedEquipmentGroupedByLocation).length > 0
    },
  },

  created() {
    this.fetchMaterialList()
  },

  methods: {
    triggerFilePreview(file) {
      this.loadPreview(file)
      this.filePreviewModal = true
      this.$nextTick(() => {
        this.filePreview = file
      })
    },
    getLocationAddress(location) {
      return location ? location.location : ''
    },

    async fetchMaterialList() {
      const placedByWorkOrderId = this.workOrder.is_for_equipment_movement && ( this.workOrder.category === 'Materiaal terugbrengen naar verhuur leverancier' || this.workOrder.category === 'Materiaal brengen naar vestiging' ) ? this.workOrder.id : null
      const collectedByWorkOrderId = this.workOrder.is_for_equipment_movement && ( this.workOrder.category === 'Materiaal ophalen bij verhuur leverancier' || this.workOrder.category === 'Materiaal ophalen bij vestiging' ) ? this.workOrder.id : null
      const workOrderId = this.workOrder.is_for_equipment_movement ? null : this.workOrder.id
      const materialListResponse = await fetchJobEquipmentLocations(this.workOrder.job_id, 1, placedByWorkOrderId, collectedByWorkOrderId, workOrderId)
      this.equipment = materialListResponse.data
    },
    getGoogleMapsUrl(location) {
      if (location.latitude && location.longitude) {
        return `${location.latitude},${location.longitude}`
      }
      return encodeURIComponent(this.getLocationAddress(location))
    },
    openDocument (file) {
      streamDownload(file)
    },
    loadPreview (file) {
      if (this.previewImages[file.hash]) {
        if (true === this.previewImages[file.hash]) {
          return;
        }
        return this.previewImages[file.hash]
      }
      this.previewImages[file.hash] = true

      const self = this
      loadImageData(file, function(imageData) {
        self.previewImages[file.hash] = imageData
        file.data = imageData;
      })
    }
  },
}
</script>

<style lang="scss">
  .workorders-view-overview {
    @import "./ViewOverview.scss";
    .google-maps-image {
      height: auto;
      padding-top: 66%;
      width: 100%;
    }

    .contacts .v-list .v-list__tile {
      padding-left: 0;
      margin-left: -6px;
    }

    h2.subtitle {
      color: #1976d2;
      font-size: 1.5em;
      font-weight: normal;
      margin-top: 15px;
    }

    .material-list {
      margin: -30px -4px 0 -4px;

      table.v-table {
        width: 100%;

        thead, tbody {
          tr td, tr th {
            padding: 4px;
            height: auto;
            font-size: 13px;
          }
        }

        thead {
          border-bottom: 1px solid #333;
        }

        thead tr {
          height: auto;
          &:first-child {
            border-bottom: 0;

            th {
            height: 36px;
              vertical-align: bottom;
            }
          }
        }

        tbody tr:nth-child(odd) {
          background: rgba(25, 118, 210, 0.1)
        }
      }
    }

    h2.filename {
      font-size: 1.1em;
      font-weight: normal;
      padding: 0 20px;
    }
  }

  .dialog-file {
    .v-card {
      height: 100%;
    }

    .v-card__text {
      height: calc(100% - 100px);
    }

    iframe {
      height: calc(100% - 30px);
      width: 100%;
    }

    .download {
      height: 30px;
    }
  }
.no_border {
  border: none !important;
}
.h-25px {
  height: 25px !important;
}

.table {
  th, td {
    padding: 0 10px !important;
    &:first-child {
      padding-left: 5px !important;
    }
  }
}
</style>
