import axios from 'axios'
import router from "@/router";

export function initInterceptors() {

  axios.interceptors.request.use(
    (request) => {
      request.headers.Accept = 'application/json'
      if(!request.headers['Content-Type']) {
        request.headers['Content-Type'] = 'application/json'
      }
      return request
    }
  )

  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data if needed
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error if needed

      if (error?.response?.status && [401, 403, 404].includes(error.response.status)) {
        if (error.response.status === 401) {
          if (window.location.pathname !== '/login') {
            window.location.href = '/login'
            alert('Session expired, login again.')
            return false
          }
        } else {
          // eenvauth
          return Promise.reject(error);
        }
      } else if(!error?.response?.data) {
        // eenvauth
        return Promise.reject(error);
      } else {
        let message = [];

        if (error.response?.data?.message) {
          message.push(error.response?.data?.message)
        } else if (error.response && error.response.data && (Array.isArray(error.response.data) || Object.keys(error.response.data).length > 0)) {
          for (let field in error.response.data) {
            message.push(error.response.data[field])
          }
        } else if (error.response?.statusText) {
          message.push(error.response?.statusText)
        }
        if (message.length) {
          throw new Error(message.join(', '));
        } else {
          throw new Error(error);
        }
      }
    }
  );

}

export default {
  initInterceptors,
}
