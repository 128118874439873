import axios from 'axios'
import useBranchSelector from '@/composable/useBranchSelector'

const { branchSelected } = useBranchSelector()

export function fetchWorkOrders(params) {
  params.branchId = branchSelected.value

  return axios.get('/work-orders', {
    params: params,
  })
}

export function fetchWorkOrder(id) {
  return axios.get('/work-orders/' + id)
}

export function updateWorkOrder(id, payload) {
  return axios.put(`/work-orders/${id}`, payload)
}

export function createWorkOrder(data) {
  return axios.post('/work-orders', data)
}

export function closeWorkOrder(id) {
  return updateWorkOrderStatus(id, 'COMPLETED')
}

export function reopenWorkOrder(id) {
  return updateWorkOrderStatus(id, 'ONGOING')
}

export function updateWorkOrderStatus(id, status) {
  return axios.put(`/work-orders/${id}/status`, {
    newStatus: status,
  })
}

export function updateWorkOrderOngoingStatus(id, payload) {
  return axios.put(`/work-orders/${id}/status-ongoing`, payload)
}

export function fetchWorkOrderHours(workOrderId) {
  return axios.get('/work-orders/' + workOrderId + '/hours')
}

export function insertWorkOrderHours(workOrderId, data) {
  return axios.post('/work-orders/' + workOrderId + '/hours', data)
}

export function updateWorkOrderHours(workOrderId, entryId, data) {
  return axios.put('/work-orders/' + workOrderId + '/hours/' + entryId, data)
}

export function deleteWorkOrderHours(workOrderId, entryId) {
  return axios.delete('/work-orders/' + workOrderId + '/hours/' + entryId)
}

export function restoreWorkOrderHours(workOrderId, entryId) {
  return axios.put('/work-orders/' + workOrderId + '/hours/' + entryId + '/restore')
}

export function fetchWorkOrderHistory(workOrderId) {
  return axios.get('/work-orders/' + workOrderId + '/history')
}

export function fetchWorkOrderNotes(workOrderId) {
  return axios.get('/work-orders/' + workOrderId + '/notes')
}

export function createWorkOrderNote(workOrderId, note) {
  return axios.post('/work-orders/' + workOrderId + '/notes', note)
}

export function updateWorkOrderNote(workOrderId, noteId, note) {
  return axios.put('/work-orders/' + workOrderId + '/notes/' + noteId, note)
}

export function uploadWorkOrderNoteImages(workOrderId, filesForm) {
  return axios.post('/work-orders/' + workOrderId + '/notes/images', filesForm, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
}

export function deleteWorkOrderNoteImage(workOrderId, fileId) {
  return axios.delete(`/work-orders/${workOrderId}/notes/images/${fileId}`)
}



